import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

import { inszuAppURL } from '../../config'

interface HeroSectionProps {}
export const HeroSection: FC<HeroSectionProps> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "mother-and-child-cropped.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <BackgroundImage Tag="section" className="relative overflow-hidden" fluid={imageData} backgroundColor={`#040e18`}>
      <div className="absolute inset-0 z-0 bg-gradient-to-r from-white to-white/50 sm:to-transparent"></div>
      <div className="container relative z-10 px-4 py-32 mx-auto space-y-4 sm:py-64 lg:py-80">
        <h1 className="text-3xl font-bold leading-relaxed text-slate-900 sm:text-4xl lg:text-[56px] lg:leading-[67px]">
          Life insurance <br /> that does more
        </h1>
        <p className="text-xl font-normal text-slate-600">Apply online in as little as 5 mins</p>
        <Link
          to={inszuAppURL}
          className="inline-block px-6 py-4 text-white transition bg-orange-600 rounded-lg appearance-none motion-reduce:transition-none hover:bg-orange-700"
        >
          Get Started
        </Link>
      </div>
    </BackgroundImage>
  )
}
