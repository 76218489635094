import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

interface WhyDifferentProps {}

export const WhyDifferent: FC<WhyDifferentProps> = () => {
  return (
    <section className="py-16">
      <div className="container px-4 mx-auto">
        <div className="flex flex-col items-center justify-center mb-10">
          <span className="text-sm tracking-widest text-orange-600 uppercase">why inszu</span>
          <h2 className="text-2xl font-bold leading-relaxed lg:text-4xl text-slate-900">Why we are different</h2>
        </div>
        <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-8 md:flex-row">
          <div className="md:flex-1 md:flex md:flex-col">
            <div className="flex px-4 space-x-8 overflow-x-auto snap-x snap-mandatory md:flex-col md:items-end md:pb-8 md:space-x-0 md:space-y-8">
              <div className="snap-always snap-start min-w-[260px] max-w-[400px] rounded shadow-lg py-8 px-6">
                <div className="mb-6">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.818 3.18198C28.5754 1.42462 31.4246 1.42462 33.182 3.18198L44.818 14.818C46.5754 16.5754 46.5754 19.4246 44.818 21.182L33.182 32.818C31.4246 34.5754 28.5754 34.5754 26.818 32.818L15.182 21.182C13.4246 19.4246 13.4246 16.5754 15.182 14.818L26.818 3.18198Z"
                      fill="url(#paint0_linear_3814_38937)"
                    />
                    <path
                      d="M5.33398 43.9998C5.33398 43.2635 5.93094 42.6665 6.66732 42.6665H25.334C26.0704 42.6665 26.6673 43.2635 26.6673 43.9998C26.6673 44.7362 26.0704 45.3332 25.334 45.3332H6.66732C5.93094 45.3332 5.33398 44.7362 5.33398 43.9998Z"
                      fill="#67ADB3"
                    />
                    <path
                      d="M18.2777 19.8361C19.8398 18.274 22.3724 18.274 23.9345 19.8361L25.4966 21.3982C27.0587 22.9603 27.0587 25.493 25.4966 27.0551L24.9423 27.6094L17.7234 20.3904L18.2777 19.8361Z"
                      fill="#67ADB3"
                    />
                    <path
                      d="M7.72873 30.3958C7.54574 30.6141 7.41102 30.8694 7.33438 31.1451L5.39121 38.1355C5.08556 39.2351 6.09754 40.2471 7.1971 39.9414L14.1875 37.9982C14.4632 37.9216 14.7186 37.7869 14.9368 37.6039L7.72873 30.3958Z"
                      fill="#67ADB3"
                    />
                    <path
                      d="M24.9421 27.6096L17.7231 20.3906L7.8469 30.2667C7.80547 30.3082 7.76598 30.3513 7.72852 30.396L14.9366 37.6041C14.9813 37.5666 15.0244 37.5271 15.0658 37.4857L24.9421 27.6096Z"
                      fill="#67ADB3"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3814_38937"
                        x1="30"
                        y1="0"
                        x2="30"
                        y2="36"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F0856B" stop-opacity="0.37" />
                        <stop offset="1" stop-color="#F28A6E" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="mb-4 text-base font-bold text-ebony">We approve more</h3>
                <p className="text-sm leading-6 text-ebony/60 mb-9">
                  We approve more individuals with adverse health conditions than any other provider.
                </p>
                <Link to="/we-approve-more" className="py-4 text-sm font-semibold underline text-keppel">
                  Learn more
                </Link>
              </div>
              <div className="snap-always snap-start min-w-[260px] max-w-[400px] rounded shadow-lg py-8 px-6">
                <div className="mb-6">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.818 3.18198C28.5754 1.42462 31.4246 1.42462 33.182 3.18198L44.818 14.818C46.5754 16.5754 46.5754 19.4246 44.818 21.182L33.182 32.818C31.4246 34.5754 28.5754 34.5754 26.818 32.818L15.182 21.182C13.4246 19.4246 13.4246 16.5754 15.182 14.818L26.818 3.18198Z"
                      fill="url(#paint0_linear_3814_38946)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.33398 41.3332C5.33398 37.6513 8.31875 34.6665 12.0007 34.6665H20.0007C23.6825 34.6665 26.6673 37.6513 26.6673 41.3332C26.6673 43.5423 24.8765 45.3332 22.6673 45.3332H9.33398C7.12485 45.3332 5.33398 43.5423 5.33398 41.3332Z"
                      fill="#67ADB3"
                    />
                    <path
                      d="M16.0007 18.6665C12.3188 18.6665 9.33398 21.6513 9.33398 25.3332C9.33398 29.0151 12.3188 31.9998 16.0007 31.9998C19.6825 31.9998 22.6673 29.0151 22.6673 25.3332C22.6673 21.6513 19.6825 18.6665 16.0007 18.6665Z"
                      fill="#67ADB3"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3814_38946"
                        x1="30"
                        y1="0"
                        x2="30"
                        y2="36"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F0856B" stop-opacity="0.37" />
                        <stop offset="1" stop-color="#F28A6E" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="mb-4 text-base font-bold text-ebony">Get your money back</h3>
                <p className="text-sm leading-6 text-ebony/60 mb-9">
                  We provide you an option to receive 75% of your premium payments returned at the end of the term.
                </p>
                <Link to="/get-your-money-back" className="py-4 text-sm font-semibold underline text-keppel">
                  Learn more
                </Link>
              </div>
              <div className="snap-always snap-start min-w-[260px] max-w-[400px] rounded shadow-lg py-8 px-6">
                <div className="mb-6">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.818 3.18198C28.5754 1.42462 31.4246 1.42462 33.182 3.18198L44.818 14.818C46.5754 16.5754 46.5754 19.4246 44.818 21.182L33.182 32.818C31.4246 34.5754 28.5754 34.5754 26.818 32.818L15.182 21.182C13.4246 19.4246 13.4246 16.5754 15.182 14.818L26.818 3.18198Z"
                      fill="url(#paint0_linear_3814_38955)"
                    />
                    <path
                      d="M14.1456 19.9178C14.8189 18.2488 17.1818 18.2488 17.8551 19.9178L20.4381 26.3206C20.5325 26.5545 20.7508 26.7151 21.0022 26.7356L27.4904 27.265C29.2496 27.4086 29.9739 29.5945 28.6486 30.7602L23.7252 35.0904C23.543 35.2507 23.4623 35.4973 23.5144 35.7343L25.0888 42.8904C25.4821 44.6779 23.4665 46.0109 21.9755 44.9493L16.387 40.9702C16.1556 40.8054 15.8451 40.8054 15.6137 40.9702L10.0252 44.9493C8.5342 46.0109 6.51862 44.6779 6.91189 42.8904L8.48626 35.7343C8.5384 35.4973 8.45766 35.2507 8.27544 35.0904L3.35193 30.7602C2.02652 29.5945 2.75086 27.4086 4.51011 27.265L10.9985 26.7356C11.2499 26.7151 11.4682 26.5545 11.5626 26.3206L14.1456 19.9178Z"
                      fill="#67ADB3"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3814_38955"
                        x1="30"
                        y1="0"
                        x2="30"
                        y2="36"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F0856B" stop-opacity="0.37" />
                        <stop offset="1" stop-color="#F28A6E" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="mb-4 text-base font-bold text-ebony">More complete protection</h3>
                <p className="text-sm leading-6 text-ebony/60 mb-9">
                  We offer critical illness protection in combination to your life insurance.
                </p>
                <Link to="/more-complete-protection" className="py-4 text-sm font-semibold underline text-keppel">
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <div className="md:flex-1 md:flex">
            <div className="py-8 -mx-4 md:px-16 md:py-16">
              <StaticImage className="w-full" src="../../images/why-different.png" alt="application screenshot" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
