import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface PlanFeaturesProps {}

const TickMark: FC = () => (
  <div className="flex-shrink-0">
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0886 0.910826C17.414 1.23626 17.414 1.7639 17.0886 2.08934L7.0886 12.0893C6.76317 12.4148 6.23553 12.4148 5.91009 12.0893L0.910093 7.08934C0.584656 6.7639 0.584656 6.23626 0.910093 5.91083C1.23553 5.58539 1.76317 5.58539 2.0886 5.91083L6.49935 10.3216L15.9101 0.910826C16.2355 0.585389 16.7632 0.585389 17.0886 0.910826Z"
        fill="#67ADB3"
      />
    </svg>
  </div>
)

const features = [
  'Affordable Life Insurance Protection Available in 10, 20 or 30 years.',
  'Rates guaranteed to remain level',
  'Death Benefit proceeds pass to beneficiaries federal income tax-free',
  'Optional Critical illness rider',
  'Rates as low as $15 monthly',
  'Issue ages 18-70',
  'Between $25,000 - $300,000 in coverage',
]
export const PlanFeatures: FC<PlanFeaturesProps> = () => {
  return (
    <section className="">
      <div className="container px-4 mx-auto">
        <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-8 md:flex-row">
          <div className="md:flex-1 md:flex md:flex-col md:justify-center">
            <div className="py-8">
              <h2 className="text-2xl font-bold leading-relaxed lg:text-4xl text-slate-900">Plan features</h2>
              <div className="py-8 space-y-4">
                {features.map((feature, index) => (
                  <div key={`feature-${index}`} className="flex items-center space-x-2">
                    <TickMark />
                    <p className="text-ebony font-base">{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:flex-1 md:flex md:flex-row-reverse">
            <StaticImage
              className="w-full h-full"
              src="../../images/woman-wearing-white-sweater-carrying-a-daughter-cropped.jpg"
              alt="application screenshot"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
