import * as React from 'react'
import { Link } from 'gatsby'

import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { HeroSection } from '../screens/home/hero-section'
import { FeaturedSection } from '../screens/home/featured-section'
import { WhyDifferent } from '../screens/home/why-different'
import { PlanFeatures } from '../screens/home/plan-features'
import { inszuAppURL } from '../config'

const IndexPage = () => {
  return (
    <main>
      <Header />
      <HeroSection />
      <FeaturedSection />
      <WhyDifferent />
      <section className="py-16 bg-foam/50 md:py-24">
        <div className="container flex flex-col items-center justify-center px-4 mx-auto space-y-8">
          <h2 className="text-2xl font-bold leading-relaxed text-center lg:text-4xl text-slate-900">
            We won't waste your time
          </h2>
          <p className="text-base text-center leading-12 lg:text-lg lg:px-32 text-ebony/50">
            Our promise to you is no gimmicks. Other online life insurance brokers will lure you in with fictitious
            quotes and not inform you about how the underwriting process works. This leads to people filling out long
            forms and ultimately getting declined. With our proprietary tech you can predetermine your eligibility in
            seconds. Just answer a few simple health and prescription drug questions and get your Honest quotes first.
          </p>
          <div className="flex">
            <Link
              to={inszuAppURL}
              className="px-6 py-4 mt-4 text-white transition rounded-lg appearance-none bg-keppel motion-reduce:transition-none hover:bg-keppel-hover"
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <PlanFeatures />
      <section className="py-16 bg-keppel md:py-24">
        <div className="container flex flex-col items-center justify-center px-4 mx-auto space-y-8">
          <h2 className="text-2xl font-bold leading-relaxed text-center text-white lg:text-4xl">
            Better coverage is just a click away
          </h2>
          <p className="text-base text-center text-white lg:text-xl sm:px-36">
            Just answer a few simple health and prescription drug questions and get your Honest quotes first.
          </p>
          <div className="flex">
            <Link
              to={inszuAppURL}
              className="px-6 py-4 mt-4 transition rounded-lg appearance-none text-slate-900 bg-white/90 motion-reduce:transition-none hover:bg-white"
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  )
}

export default IndexPage
